import React from 'react'

const LoginRoute = React.lazy(() => import('./views/login'))
const CreateMissionRoute = React.lazy(() => import('./views/missions/CreateMissions'))
const CurrentMissionsRoute = React.lazy(() => import('./views/missions/agent_Missions/CurrentMissions'))
const AgentHomeRoute = React.lazy(() => import('./views/missions/agent_Missions/agent_home'))
const AgentProfileRoute = React.lazy(() => import('./views/other/profile'))
const AgentFaqRoute = React.lazy(() => import('./views/other/faq'))
const AgentContactRoute = React.lazy(() => import('./views/other/contact'))
const SingleCurrentMissionsRoute = React.lazy(() => import('./views/missions/agent_Missions/DetailsSingleCurrentMission'))
const ErrorRoute = React.lazy(() => import('./views/errorPage'))
const CreateNavetteRoute = React.lazy(() => import('./views/missions/navettes'))
const missionsFinishedRoute = React.lazy(() => import('./views/missions/agent_Missions/missionsFinished'))
const CurrentConvoyeurMissionsRoute = React.lazy(() => import('./views/missions/convoyeur_Missions/CurrentConvoyeurMissions'))
const AdminCurrentMissionsRoute = React.lazy(() => import('./views/missions/admin_Missions/AdminCurrentMissions'))
const AdminfinishedtMissionsRoute = React.lazy(() => import('./views/missions/admin_Missions/AdminFinishedMissions'))
const AdminSingleMissionRoute = React.lazy(() => import('./views/missions/admin_Missions/AdminSingleMission'))
const sacGetAdminRoute = React.lazy(() => import('./views/sacs/sacGetAdmin'))
const AdmindeletedtMissionsRoute = React.lazy(() => import('./views/missions/admin_Missions/DeletedMissions'))
const SearchMissionsRoute =  React.lazy(() => import('./views/missions/admin_Missions/SearchMissions'))
const PauseRoute =  React.lazy(() => import('./views/missions/currentPause'))
const Proforma =  React.lazy(() => import('./views/missions/agent_Missions/Proforma'))
const DashboardAdminRoute = React.lazy(() => import('./views/missions/admin_Missions/DashboardAdmin'))
const Chauffeurs = React.lazy(() => import('./views/chauffeurs/Chauffeurs'))
const Convoyeurs = React.lazy(() => import('./views/convoyeurs/Convoyeurs'))
const Vehicules = React.lazy(() => import('./views/vehicules/Vehicules'))
const ConvoyeurSingleMission = React.lazy(() => import('./views/missions/convoyeur_Missions/ConvoyeurSingleMission'))
const SupervisionCurrent = React.lazy(() => import('./views/maps/mapCurrentMissions'))
const SupervisionFinished = React.lazy(() => import('./views/maps/mapFinishedMissions'))


const routes = [
    { path: '/login', exact: true, name: 'Login',element:LoginRoute },
    { path: '/', exact: true, name: 'Agent_Home',element:AgentHomeRoute },
    { path: '/error', exact: true, name: 'Error',element:ErrorRoute },

    //Agent
    { path: '/agent/profile', exact: true, name: 'Profile',element:AgentProfileRoute },
    { path: '/agent/faq', exact: true, name: 'Faq',element:AgentFaqRoute },
    { path: '/agent/contact', exact: true, name: 'Contact',element:AgentContactRoute },
    { path: '/agent/create_mission', exact: true, name: 'CreateMission',element:CreateMissionRoute },
    { path: '/agent/current_missions', exact: true, name: 'CurrentMissions',element:CurrentMissionsRoute },
    { path: '/agent/current_missions/mission/:missionId', exact: true, name: 'GetCurrentMissionSingle',element:SingleCurrentMissionsRoute },
    { path: '/agent/create_navette', exact: true, name: 'CreateNavette',element:CreateNavetteRoute },
    { path: '/agent/missions_terminées', exact: true, name: 'MissionsFinished',element:missionsFinishedRoute },
    { path: '/agent/current_missions/mission/:missionId/proforma', exact: true, name: 'Proforma',element:Proforma },
    { path: '/agent/liste_chauffeurs', exact: true, name: 'Chauffeurs',element:Chauffeurs },
    { path: '/agent/liste_vehicules', exact: true, name: 'Vehicules',element:Vehicules },
    { path: '/agent/liste_convoyeurs', exact: true, name: 'Convoyeurs',element:Convoyeurs },
    //Convoyeur
    { path: '/convoyeur/missions', exact: true, name: 'CurrentConvoyeurMissions',element:CurrentConvoyeurMissionsRoute },
    { path: '/convoyeur/mission/:id', exact: true, name: 'ConvoyeurSingleMission',element:ConvoyeurSingleMission },
    { path: '/convoyeur/missions_en_pause', exact: true, name: 'CurrentPause',element:PauseRoute },

    //Admin
    { path: '/admin/current_missions', exact: true, name: 'AdminCurrentMissions',element:AdminCurrentMissionsRoute },
    { path: '/admin/finished_missions', exact: true, name: 'AdminFinishedMissions',element:AdminfinishedtMissionsRoute },
    { path: '/admin/current_missions/mission/:missionId', exact: true, name: 'AdminSingleMission',element:AdminSingleMissionRoute },
    { path: '/admin/search/sacs', exact: true, name: 'SacGetAdmin',element:sacGetAdminRoute },
    { path: '/admin/deleted_missions', exact: true, name: 'AdminDeletedMissions',element:AdmindeletedtMissionsRoute },
    { path: '/admin/search/missions', exact: true, name: 'SearchMissions',element:SearchMissionsRoute },
    { path: '/admin/dashboard/', exact: true, name: 'DashboardAdmin',element:DashboardAdminRoute },
    { path: '/admin/maps/current', exact: true, name: 'SupervisionCurrent',element:SupervisionCurrent },
    { path: '/admin/maps/finished', exact: true, name: 'SupervisionFinished',element:SupervisionFinished },
    
]

export default routes